import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Image from "../components/image"

export default ({ data }) => (
	<Layout>
		<SEO title="Inkling — Ryan Teuscher" />
		<nav>
			<Link to="/">
				<span className="material-icons">arrow_back</span>
			</Link>
		</nav>
		<header>
			<h1>Inkling. A new standard in learning.</h1>
			<h3>Acquired &mdash; Senior Product Designer</h3>
			<p>
				What began as a laborious process of rebuilding textbooks by hand became
				a platform for reinventing the way people learn. Inkling is comprised of
				a full ecosystem: Habitat, Inkling for Web, Inkling for iPad & iPhone,
				as well as the Inkling Content Discovery Platform. Each offering helps
				Inkling transform traditional learning materials like textbooks into
				interactive digital editions. I'm proud to have led design initiatives
				reaching across offerings from the fledgeling 15-ish person startup to a
				125 member team Inkling.
			</p>
		</header>
		<section>
			<figure>
				<Image alt="Assessments" filename="slide-inkling-test.png" />
			</figure>
			<article>
				<h2>Translating a product to a new device</h2>
				<p>
					What happens when your book is connected to the web? Why don't more
					people read long form content in the browser? How can we leverage
					search now that we have a keyboard again? We asked questions, lots of
					them, before embarking on the creation of Inkling's second client.
					Each answer led us to believe that customers want a delicate balance
					of consistency across platforms and native interaction. We followed
					this principle with vigor in the creation of Inkling for Web. Each and
					every interaction from the iPad was rethought within the context of
					the web on both laptop and desktop devices.
				</p>
			</article>
		</section>
		<section>
			<figure>
				<Image alt="Sidebar" filename="slide-inkling-sidebar.png" />
			</figure>
			<article>
				<h2>Shrinking a textbook</h2>
				<p>
					Along with the new form factor came a host of new challenges and
					opportunities. Connecting the structured content of an Inkling title
					to the web meant deeper knowledge on any given topic. Wikipedia became
					the new connected source of reference. A meta layer emerged allowing
					quick access to topical information. We chose to acknowledge the
					ergonomic limitations of reading in the browser in favor of increased
					productivity and reference-based learning.
				</p>
			</article>
		</section>
		<section>
			<figure>
				<Image alt="Store" filename="slide-inkling-store.png" />
			</figure>
			<article>
				<h2>The back cover</h2>
				<p>
					Consolidating information from publishers, marketing, and product
					teams, Inkling.com and Store complete a flexible purchase flow. You
					can buy by the chapter, unit, or book, and all purchases automatically
					sync across your devices. Each title detail page supports multiple
					media formats while highlighting the power of the platform. The
					Inkling brand remains consistent and clear through every point of
					contact with each customer.
				</p>
			</article>
		</section>
		<section>
			<figure>
				<Image alt="Open Inkling" filename="slide-inkling-open.png" />
			</figure>
		</section>
	</Layout>
)
